import React from "react"
import {useTranslation} from "react-i18next"

import './equipment.component.less';

import EquipmentMain from "../../assets/img/equipment/equipment-main.jpg";
import Equipment1 from "../../assets/img/equipment/equipment-1.jpg";
import Equipment1M from "../../assets/img/equipment/equipment-1M.jpg";
import Equipment2 from "../../assets/img/equipment/equipment-2.jpg";
import EquipmentReference from "../../assets/img/equipment/equipment-reference.jpg";

import Seo from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const EquipmentComponent = () => {
    const { i18n, t } = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo 
                description={t("metaDescriptionEquipment")} 
                lang={i18n.language} 
                title={t("titleEquipment")} 
            />
            <div className="equipment">
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block main-block--equipment">
                    <div className="main-block__text">
                        <div className="main-block__title main-block__title--medium_width">
                            <span dangerouslySetInnerHTML={{__html: t("professional")}} /> <span className="main-block__title-purple" dangerouslySetInnerHTML={{__html: t("airQualityMonitoringEquipment")}} />
                        </div>
                        <div className="main-block__description main-block__description--single main-block__description main-block__description--single-2">
                            <div dangerouslySetInnerHTML={{__html: t("reliabilityOfCityAirEquipment")}} />
                        </div>
                    </div>
                    <div className="main-block__img main-block__img--equipment">
                        <img alt="" className="main-block__main-img"
                             src={EquipmentMain}/>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block big-title__2">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("realTimeAirQualityMonitorCityAir")}} />
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <div dangerouslySetInnerHTML={{__html: t("cityAirMonitorMayBeInstalled")}} />
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <div dangerouslySetInnerHTML={{__html: t("everyMinuteMonitorsTransfer")}} />
                                </div>
                            </div>
                        </div>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title margin-top-50"><div dangerouslySetInnerHTML={{__html: t("sensorsForPollutants")}} />
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <div dangerouslySetInnerHTML={{__html: t("modularPlatformAllowsToCreate")}} />
                                </div>
                                <div className="borders-block__col--subtitle">
                                    <div dangerouslySetInnerHTML={{__html: t("cityAirHub")}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="equipment__img display-none-mobile">
                    <img alt="" src={Equipment1}/>
                </div>
                <div className="equipment__img display-block-mobile">
                    <img alt="" src={Equipment1M}/>
                </div>
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border wrapper_block--yellow big-title">
                    <div className="big-title__width">
                    <div dangerouslySetInnerHTML={{__html: t("keyAdvantagesOfCityAir")}} />
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div
                                    className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("measurementAccuracy")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point ">
                                    <div dangerouslySetInnerHTML={{__html: t("periodicalComparativeTestResults")}} />
                                </div>
                                <div className="borders-block__col--img">
                                    <img alt="" src={EquipmentReference} />
                                </div>
                                <div className="borders-block__col--small-text">
                                    <div dangerouslySetInnerHTML={{__html: t("measurementsOfCityAirEquipment")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div
                                    className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("abilityToOperateIn")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point">
                                    <div dangerouslySetInnerHTML={{__html: t("monitorsDemonstrateFullOperational")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal borders-block__hr--mobile" />
                        </div>
                        <div className="borders-block__hr" />
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("compactSize")}} />
                                </div>
                                <div
                                    className="borders-block__col--point">
                                    <div dangerouslySetInnerHTML={{__html: t("dueToCompactSize")}} />
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--subtitle-2">
                                    <div dangerouslySetInnerHTML={{__html: t("monitorParameters")}} />
                                </div>
                                <div
                                    className="borders-block__col--subtitle">
                                    <strong><div dangerouslySetInnerHTML={{__html: t("mm150320420")}} /></strong>
                                </div>

                                <div
                                    className="borders-block__col--subtitle borders-block__col--subtitle-2 ">
                                    <div dangerouslySetInnerHTML={{__html: t("sensorParameters")}} />
                                </div><div className="borders-block__col--subtitle ">
                                    <strong><div dangerouslySetInnerHTML={{__html: t("mm205205255")}} /></strong>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("continuousMeasurementsInRealTime")}} />
                                </div>
                                <div className="borders-block__col--subtitle borders-block__col--point">
                                    <div dangerouslySetInnerHTML={{__html: t("cityAirMonitorsTransfer")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--title">
                                    <div dangerouslySetInnerHTML={{__html: t("operationalDuringPowerOutages")}} />
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <div dangerouslySetInnerHTML={{__html: t("theMonitorRemainsFully")}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="equipment__img">
                    <img alt="" src={Equipment2}/>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--top-padding wrapper_block__block--no-border title-and-img">
                    <div className="borders-block__col">
                        <div className="title-and-img__title">
                            <div dangerouslySetInnerHTML={{__html: t("whatDoesTypicalCityAirMonitorMeasures")}} />
                        </div>
                        <div className="title-and-img__description">
                            <div dangerouslySetInnerHTML={{__html: t("monitoringPostsAreDesigned")}} />
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("cityAirStation")}} />
                                </div>
                                <div className="borders-block__col--big-text ">
                                    <div dangerouslySetInnerHTML={{__html: t("temperatureHumidityAtmospheric")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("moduleG1")}} />
                                </div>
                                <div className="borders-block__col--big-text">
                                    <div dangerouslySetInnerHTML={{__html: t("moduleG1CO")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div
                                    className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("moduleG2")}} />
                                </div>
                                <div className="borders-block__col--big-text">
                                    <div dangerouslySetInnerHTML={{__html: t("moduleG2SO")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal borders-block__hr--mobile" />
                        </div>
                        <div className="borders-block__hr" />
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4</div>
                                <div className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("meteostation")}} />
                                </div>
                                <div className="borders-block__col--big-text">
                                    <div dangerouslySetInnerHTML={{__html: t("temperatureHumidityPressure")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">5
                                </div>
                                <div className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("integrated3dPartyModules")}} />
                                </div>
                                <div className="borders-block__col--big-text">
                                    <div dangerouslySetInnerHTML={{__html: t("anyCombinationsOfAnyAirQuality")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">6</div>
                                <div
                                    className="borders-block__col--title borders-block__col--title-3">
                                    <div dangerouslySetInnerHTML={{__html: t("modulesComingSoon")}} />
                                </div>
                                <div
                                    className="borders-block__col--big-text">
                                    <div dangerouslySetInnerHTML={{__html: t("modulesComingSoonCH")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal display-block-mobile" />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

EquipmentComponent.propTypes = {}

EquipmentComponent.defaultProps = {}

export default EquipmentComponent
